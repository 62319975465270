<template>
  <h3 class="bh-text bh-text-left bh-text-bold">Home</h3>
  <div class="row m-0">
    <div class="col-3">
      <div class="bh-favorite bh-bg-purple">
        <p>Survey</p>
        <div class="row">
          <div class="col-8">
            <h5><strong>What type of food</strong></h5>
            <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
          </div>
          <div class="col-4">
            <p>Responses</p>
            <h1>57</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="bh-favorite bh-bg-yellow">
        <p>Daily</p>
        <div class="row">
          <div class="col-8">
            <h5><strong>8 glasses of water</strong></h5>
            <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
          </div>
          <div class="col-4">
            <p>Dailt missions</p>
            <h1>17</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="bh-favorite bh-bg-blue">
        <p>Weekly</p>
        <div class="row">
          <div class="col-8">
            <h5><strong>Run 2 km for 7 days</strong></h5>
            <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
          </div>
          <div class="col-4">
            <p>Weekly challenges</p>
            <h1>17</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="bh-favorite bh-bg-pink">
        <p>Event</p>
        <div class="row">
          <div class="col-8">
            <h5>
              <strong>Accumulate 15 km and eat heathy for three days</strong>
            </h5>
            <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit.</p>
          </div>
          <div class="col-4">
            <p>Time left</p>
            <h1>57</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0 mt-4">
    <div class="col-6">
      <div class="bh-card">
        <h5><strong>Responses</strong> / Survey</h5>
        <div
          class="row bh-item-secondary mb-2"
          v-for="survey in surveys"
          :key="survey._id"
        >
          <div class="col-6">
            <p class="bh-text-background-secondary">
              <strong>{{ survey.name }}</strong>
            </p>
          </div>
          <div class="col-3 text-center">
            <p class="text-secondary">Responses</p>
            <h5><strong>{{ survey.answerCount }}</strong></h5>
          </div>
          <div class="col-3 text-center">
            <p :class="survey.status + ' bh-text-background'">
              {{ survey.status }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginService from "@/services/LoginService.js";
import SurveyService from "@/services/SurveyService.js";
import moment from "moment";
import VueCookies from "vue-cookies";

export default {
  name: "login",
  data() {
    return {
      surveys: [],
    }
  },
  validations: {},
  mounted() {
    this.VerifyCredentials();
    this.GetSurvey();
  },
  methods: {
    VerifyCredentials() {
      return LoginService.VerifyCredentials()
        .then(async ({ data }) => {})
        .catch((e) => {
          this.error = e.response.data.response;
          //window.location.href = "/error";
        });
    },
    GetSurvey() {
      return SurveyService.GetSurveys(5)
        .then(async ({ data }) => {
          this.surveys = data.response;
          console.log(data);
          this.isLoadingPage = false;
        })
        .catch((e) => {
          console.log(e.response);
          window.location.href = "/error";
        });
    },
    FilterDate(date) {
      if (date) return moment(String(date)).format("MM/DD/YYYY");
    },
  },
};
</script>
<style scoped>
@import "../assets/css/Home.css";
</style>